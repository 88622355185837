@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Chelsea+Market&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fahkwang&display=swap");
@font-face {
  font-family: "CBGBFont";
  src: local("CBGBFont"), url('../fonts/CBGBFont.eot');
  src: url('../fonts/CBGBFont.eot?#') format('embedded-opentype'),
      url('../fonts/CBGBFont.woff') format('woff'),
      url('../fonts/CBGBFont.ttf') format('truetype');
}
@font-face {
  font-family: "Acier";
  src: local("Acier"), url(../fonts/Acier-DisplaySolid_1.woff) format("woff");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}
@font-face {
  font-family: "Avenir LT Std";
  src: local("Avenir LT Std"), url(../fonts/FontsFree-Net-AvenirLTStd-Book.woff) format("woff");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}
@font-face {
  font-family: "CheapPineRegular";
  src: local("CheapPineRegular"), url('../fonts/CheapPineRegular.otf');
}
html {
  height: 100%;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
